
import Vue from "vue";
import {mapActions, mapState} from "vuex";
import OrderItem from "@/components/order/OrderItem.vue";

export default Vue.extend({
  name: 'OrderIndex',
  components: {OrderItem},
  computed: {
    ...mapState('order', {
      items: 'items',
      loading: 'loading',
      pagination: 'pagination'
    }),
    ...mapState('auth', {
      sub: 'sub'
    })
  },
  methods: {
    ...mapActions('order', {
      fetch: 'fetch',
      changePage: 'changePage',
    }),
    async initialize() {
      this.fetch({customer: this.sub})
    }
  },
  mounted() {
    this.initialize();
  }
})
