
import {TaxonModule} from "@/store/models/Taxon";
import Vue from "vue";

export default Vue.extend({
  name: 'OrderItem',
  props: {
    order: TaxonModule
  },
  data() {
    return {
      orderUrl: `/order/${this.order?.uuid}`
    }
  }
})
